<template>
  <div>
    <headerCustom></headerCustom>
    <div class="profile-section main-content">
      <div class="heading-sec">
        <div class="row">
          <div class="col-md-4 column">
            <div class="heading-profile">
              <!-- <h3>{{ title }}</h3> -->
            </div>
          </div>
        </div>
      </div>
      <!-- title section end -->
      <!-- store profile section start -->
      <div class="store-profile-content p-3">
        <!-- store plan section start -->
        <b-card>
          <div class="store-plan-section border-bottom">
            <div class="store-photo-section border-bottom px-3 py-3">
              <div class="d-flex">
                <div class="store-image-section">
                  <img :src="img" alt="img" class="store-image" />
                </div>
                <div class="hotel-name pl-3 pr-5">
                  <div class="font-16 font-weight-bold">
                    {{ hotelInfo.name }}
                  </div>
                  <!-- <div class="font-10 ">GST:{{ store.gstNumber }}</div> -->
                </div>
                <div class="last-login-date pl-3">
                  <div class="font-weight-bold">Last Login Date &amp; Time</div>
                  <div>
                    {{ loginDate }}
                  </div>
                </div>
                <!-- <div class="edit-section ml-auto font-10 shadow-none">
                  <div class="edit font-12 pointer">
                    <span> <b-button> Edit</b-button></span>

                    <span>
                      <font-awesome-icon :icon="['fas', 'pencil-alt']" />
                    </span>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="my-plan-section border-bottom px-3 py-3">
              <!-- <div class="d-flex justify-content-between align-items-center mb-2">
                <div class="black font-18 text-uppercase font-weight-bold">MY PLANS</div>
                <div class="update-plan-btn">
                  <button type="button" class="btn btn-sm bg-navy-blue shadow-none btn-secondary">
                    Update Plan
                  </button>
                </div>
              </div> -->
              <div class="d-flex font-14">
                <!-- <div class="font-14">{{ store.packageName }}</div> -->
                <!-- date of signup -->
                <div class="light-green mr-5 ">
                  <div class="font-weight-bold">Date of Sign-up</div>
                  <div>{{ hotelInfo.user.userCreatedAt | moment("DD/MM/YYYY") }}</div>
                </div>
                <!-- current package -->
                <div class="mx-5">
                  <div class="font-weight-bold">Current Package</div>
                  <div>{{ hotelInfo.package.name }} {{ hotelInfo.package.type }}</div>
                </div>
                <!-- current status -->
                <div class="mx-5">
                  <div class="font-weight-bold">Current Status</div>
                  <div v-if="!hotelInfo.package">
                    Signup Completed
                  </div>
                  <div v-else-if="hotelInfo.package && hotelInfo.progressCompleted === 'false'">
                    Package Selected
                  </div>
                  <div v-else-if="hotelInfo.progressCompleted">
                    Finish
                  </div>
                </div>
                <div class="ml-auto">
                  <button type="button" class="btn btn-sm bg-navy-blue shadow-none btn-secondary">
                    Update Plan
                  </button>
                </div>
              </div>
            </div>
            <div class="theme-section px-3 py-3">
              <div class="d-flex font-14">
                <div class="open-sans-bold">
                  <div class="font-weight-bold">Start Date &amp; End Date</div>
                  <div>
                    {{ hotelInfo.createdAt | moment("DD/MM/YYYY") }} -{{
                      hotelInfo.expiredAt | moment("DD/MM/YYYY")
                    }}
                  </div>
                </div>
                <div class="pl-5">
                  <div class="font-weight-bold">Remainig Days</div>
                  <div>{{ remainingDay }}</div>
                </div>
                <!-- <div class="silver pl-5">Skyline 2 Theme</div> -->
              </div>
            </div>
          </div>
          <!-- store plan section end -->
          <!-- store-details section start -->
          <div class="store-detail-section">
            <!-- <div class="border-bottom px-3 py-2">
              <div class="store-title">STORE DETAILS</div>
            </div> -->
            <div class="registered-address-section border-bottom px-3 py-3">
              <div class="font-weight-bold font-14">REGISTERED ADDRESS:</div>
              <div>{{ hotelInfo.user.invoice.address1 }}</div>
              <div class="d-flex">
                <div>
                  country:
                  <span>
                    {{ hotelInfo.user.invoice.rcountry }}
                  </span>
                </div>
                <div class="pl-3">
                  state:
                  <span>
                    {{ hotelInfo.user.invoice.rstate }}
                  </span>
                </div>
                <div class="pl-3">
                  city:
                  <span>
                    {{ hotelInfo.user.invoice.rcity }}
                  </span>
                </div>
              </div>
            </div>

            <div class="registered-address-section border-bottom px-3 py-3 font-14">
              <div class=" font-14 font-weight-bold">MAILING ADDRESS:</div>
              <div>{{ hotelInfo.user.invoice.address2 }}</div>
              <div class="d-flex">
                <div>
                  country:
                  <span>
                    {{ hotelInfo.user.invoice.bcountry }}
                  </span>
                </div>
                <div class="pl-3">
                  state:
                  <span>
                    {{ hotelInfo.user.invoice.bstate }}
                  </span>
                </div>
                <div class="pl-3">
                  city:
                  <span>
                    {{ hotelInfo.user.invoice.bcity }}
                  </span>
                </div>
              </div>
            </div>
            <div class="contact-section px-3 py-3 font-14">
              <div class="font-weight-bold ">CONTACT:</div>
              <div class="d-flex justify-content-between">
                <div>
                  <span class="font-weight-bold"> Emails: </span>
                  <span>{{ hotelInfo.user.email }}</span>
                </div>
                <div>
                  <span class="font-weight-bold"> Contact Number: </span>
                  <span>
                    {{ hotelInfo.user.phone }}
                  </span>
                </div>
                <div>
                  <span class="font-weight-bold"> Contact Person Name:</span>

                  <span>
                    {{ hotelInfo.user.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </b-card>
        <!-- store details section end -->
      </div>
      <!-- store profile section end -->
    </div>
  </div>
</template>
<script>
import moment from "moment";
import img1 from "@/assets/images/logo.png";
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      title: "My Store",
      hotelId: "",
      hotelInfo: {
        name: "",
        createdAt: "",
        expiredAt: "",
        progressCompleted: "",
        user: {
          name: "",
          email: "",
          phone: "",
          userCreatedAt: "",
          invoice: {
            address1: "",
            rcity: "",
            rstate: "",
            rcountry: "",
            address2: "",
            bcity: "",
            bcountry: "",
            bstate: ""
          }
        },
        package: {
          name: "",
          type: ""
        }
      },
      loginDate: "",
      remainingDay: "",
      img: img1
      //   store: {
      //     img: img1,
      //     name: "Hotel Name",
      //     gstNumber: "123456789123456",
      //     packageName: "Premium Plan",
      //     dateOfSignup: "01 Aug 2020",
      //     registeredAddress: "A-8/7, Rd Number 24, Sindhi Colony, Sion, Mumbai, Maharashtra 400022",
      //     latitude: "19.0390N",
      //     longitude: "72.8619E",
      //     mailingAddress: "A-8/7, Rd Number 24, Sindhi Colony, Sion, Mumbai, Maharashtra 400022",
      //     email: "sample@sample.com",
      //     contactNumber: "8877554487",
      //     contactPerson: "Abc",
      //     currentStatus: "Active",
      //     startDate: "20/6/2020",
      //     endDate: "30/6/2020",
      //     remainingDate: ""
      //   }
    };
  },
  created() {
    this.hotelId = this.$route.params.id;

    service.getOneHotelForInfoAdmin(this.hotelId, data => {
      if (data.status === 200) {
        this.hotelInfo = data.data;
        this.loginDate =
          this.hotelInfo.user.loginData && this.hotelInfo.user.loginData.length > 0
            ? moment(
                this.hotelInfo.user.loginData[this.hotelInfo.user.loginData.length - 1].lastLogin
              ).format("DD/MM/YYYY hh:mm A")
            : "";
        //calculate remaing days
        var date1 = new Date(this.hotelInfo.expiredAt);
        var date2 = new Date();

        this.remainingDay = Math.floor(
          (Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate()) -
            Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())) /
            (1000 * 60 * 60 * 24)
        );
      }
    });
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
.profile-section {
  .store-profile-content {
    .card {
      margin: 0px;
      width: 100%;
      .card-body {
        padding: 0px;
        .store-plan-section {
          .store-photo-section {
            .store-image-section {
              width: 20%;
              height: 20%;
              .store-image {
                width: 100%;
                height: 100%;
              }
            }
            .edit-section {
              .edit {
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
          .my-plan-section {
            .update-plan-btn {
              a {
                color: $white;
                &:hover {
                  color: $white;
                  text-decoration: none;
                }
              }
              .btn-secondary {
                &:hover,
                &:focus {
                  color: $white;
                  background-color: $navy-blue;
                  border-color: $navy-blue;
                }
              }
              .btn-link a {
                color: $navy-blue;
                &:hover {
                  color: $navy-blue;
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .store-detail-section {
          .store-title {
            color: $navy-blue;
            font-size: $font-18;
            font-family: $roboto-bold;
          }
        }
        a {
          color: inherit;
          &:hover {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }
  }
  .font-14 {
    font-size: 16px;
  }
}
</style>
